<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Transferencias</span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <v-btn dark color="#023145" width="140" class="float-right" @click="handleShowFilters">
                        Filtros
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-1">
                    <v-btn
                        dark 
                        color="success" 
                        width="140" 
                        class="float-right"
                        @click="handleDownloadReport">
                        Descargar XLS
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="transfers"
                            item-key="id"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="55vh"
                            fixed-header
                            :loading="loading"
                            :options.sync="options"
                            :server-items-length="paginated.total"
                            loading-text="Loading... Please wait">
                            <template v-slot:top>
                                <span  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todas las tranferencias
                                </span>
                                <v-divider></v-divider>
                            </template>
                            
                            <template v-slot:item.origin_id="{item}">
                                <span v-if="item.departure">{{ item.departure.name }}</span>
                            </template>

                            <template v-slot:item.destination_id="{item}">
                                <span v-if="item.arrival">{{ item.arrival.name }}</span>
                            </template>

                            <template v-slot:item.register_date="{item}">
                                <span>{{ item.register_date | moment('DD/MM/YYYY') }}</span>
                            </template>

                            <template v-slot:item.hour_register="{item}">
                                <span>{{ item.register_date | moment('hh:mm') }}</span>
                            </template>

                            <template v-slot:item.register_by="{item}">
                                <span v-if="item.sender">
                                    {{ item.sender.employee.fullname }}
                                </span>
                            </template>

                            <template v-slot:item.status="{item}">
                                <span :class="'label-status transfer-' + item.status">
                                    <v-icon v-if="item.status == 'recibida'" size="11" color="#4CAF50">fas fa-check</v-icon>
                                    <v-icon v-if="item.status == 'enviada'" size="11" color="#3F51B5">fas fa-paper-plane</v-icon>
                                    <v-icon v-if="item.status == 'parcial'" size="11" color="#FFC107">fas fa-clone</v-icon>
                                    <v-icon v-if="item.status == 'anulada'" size="11" color="#F44336">fas fa-times-circle</v-icon>
                                    {{ item.status }}
                                </span>
                            </template>

                            <template v-slot:item.received_date="{item}">
                                <span v-if="item.canceled_date">
                                    {{ item.canceled_date | moment('DD/MM/YYYY') }}
                                </span>
                                <span v-else-if="item.received_date">
                                    {{ item.received_date | moment('DD/MM/YYYY') }}
                                </span>
                                
                            </template>

                            <template v-slot:item.hour_receive="{item}">
                                <span v-if="item.canceled_date">
                                    {{ item.canceled_date | moment('hh:mm') }}
                                </span>
                                <span v-else-if="item.received_date">
                                    {{ item.received_date | moment('hh:mm') }}
                                </span>
                            </template>

                            <template v-slot:item.received_by="{item}">
                                <span v-if="item.canceller">
                                    {{ item.canceller.employee.fullname }}
                                </span>
                                <span v-else-if="item.receiver">
                                    {{ item.receiver.employee.fullname }}
                                </span>                                
                            </template>

                            <template v-slot:item.detail="{item}">
                                <v-btn icon small dark color="blue" @click="handleViewDetail(item)">
                                    <v-icon size="14">fas fa-bars</v-icon>
                                </v-btn>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <transfers-filters ref="transferFilters"></transfers-filters>
    </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import router from '@/router/index';
import TransfersFilters from '../../../components/reports/TransfersFilters';
import OfficeLogin from '../../../components/auth/OfficeLogin';

export default {
    name: 'ReportTransfers',
    components: {
        TransfersFilters,
        OfficeLogin
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Reportes',
                    disabled: true,
                    href: '',
                }
            ],
            headers: [
                { text: 'ORIGEN', value: 'origin_id', sortable: false },
                { text: 'DESTINO', value: 'destination_id', sortable: false },
                { text: 'F. REGISTRO', value: 'register_date', sortable: false },
                { text: 'H. REGISTRO', value: 'hour_register', sortable: false },
                { text: 'REGISTRADO POR', value: 'register_by', sortable: false },
                { text: 'ESTADO', value: 'status', sortable: false },
                { text: 'FECHA', value: 'received_date', sortable: false },
                { text: 'HORA', value: 'hour_receive', sortable: false },
                { text: 'USUARIO', value: 'received_by', sortable: false },               
                { text: '', value: 'detail', sortable: false, align: 'end' },
            ],
            date_init: this.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
            date_end: this.$moment().format('YYYY-MM-DD'),
          loading: false,
          dataTable: {page: 1},
          options: {},
          pageSelected: 1,
          perPage: 10,
          search_product: null
        }
    },
  watch: {
    options: {
      handler() {
        this.loading=true
        this.perPage = this.options.itemsPerPage
        this.pageSelected = this.options.page
        const search = this.search_product
        if (search != null && search.trim().length > 0) {
          // this.perPage = -1
          this.getTransfers({
            paginated: true, page: this.pageSelected, itemsPerPage: this.perPage,
            name: search, company_id: this.company_id,
            date_from: this.date_init,
            date_until: this.date_end,
          }).then(res=>this.loading=false).catch(err=>this.loading=false)
        } else {
          this.getTransfers({
            paginated: true, page: this.pageSelected, itemsPerPage: this.perPage, company_id: this.company_id,
            date_from: this.date_init,
            date_until: this.date_end,
          }).then(res=>this.loading=false).catch(err=>this.loading=false)
        }
      },
      deep: true,
    }
  },
    computed: {
        ...mapState('authentication', [
            'company_id'
        ]),
        ...mapState('report-transfers', [
            'transfers',
            'file_excel',
            'filters',
            'paginated'
        ])
    },
    methods: {
        ...mapActions('offices', [
            'getOffices'
        ]),
        ...mapActions('report-transfers', [
            'getTransfers',
            'selectTransfer',
            'transferExcel',
            'setFilters',
            'setTransfers'
        ]),
        handleShowFilters () {
            this.$refs.transferFilters.show();
        },
        async handleDownloadReport () {
            let filters_data = Object.assign({}, this.filters);
            filters_data.company_id = this.company_id;
            await this.transferExcel(filters_data);
            if (this.file_excel) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_excel;
                window.open(url);
            }
        },
        handleViewDetail (transfer) {
            this.selectTransfer(transfer);
            router.push('/app/reportes/detalle-transferencias');
        }
    },
    created () {
        this.setFilters({
            company_id: this.company_id,
            date_from: this.date_init,
            date_until: this.date_end,
          paginated:true
        });
        // this.loading=true
        // this.getTransfers(this.filters).then(res=>this.loading=false)
        // .catch(err=>this.loading=false);
        this.getOffices({
            company_id: this.company_id
        })
    },
    destroyed () {
        this.setTransfers([]);
    }
}
</script>

<style scoped>

</style>